@import "custom";

body {
  padding-top: 50px;
}

.btn-circle {
  text-align: center;
  padding: 0;
  border-radius: 50%;
}

.btn-circle-lg {
  width: 150px;
  height: 150px;
  padding: 20px;
  margin-bottom: 20px;
}

#intro {
  background-color: #272b30;
  color: #fff;
}

#features {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #eaf1f1;
}

#event-table {
  & .band-name {
    width: 40%;
  }
  & .venue-name {
    width: 30%;
  }
  & .date {
    width: 20%;
  }
  & .price {
    width: 10%;
  }
}

#event-description {
  white-space: pre-wrap;
}

a {
  text-decoration: none;
}